import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { getLowestRoute } from '@core/utils';
import { HeaderConfig } from '@store/core/core.interface';
import { filter, map, Observable, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  routeData: Observable<any> = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    startWith(this.router),
    map(() => getLowestRoute(this.activatedRoute)),
    map((routeData) => routeData.snapshot.data)
  );

  enableBack = true;
  constructor(public router: Router, public activatedRoute: ActivatedRoute, public matDialog: MatDialog) {
    this.setBack();
  }

  setBack() {
    this.routeData.subscribe((res) => this.onRouteChange(res));
  }

  onRouteChange(headerConfig: HeaderConfig) {
    if (headerConfig?.onPopState) {
      this.enableBack = false;
      history.pushState(null, 'null', location.href);
    } else {
      this.enableBack = true;
    }
  }
}
