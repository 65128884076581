import { ShippingAddress, UserAccountInfo } from '@api/index';
import { EMAIL_VERIFICATION_STATUS } from '@core/constants';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CoreState, LoaderState } from '@store/core/core.interface';
import {
  selectCore,
  selectFilteredAccountDisableReasons,
  selectFinancialInstitutions,
} from '@store/core/core.selectors';
import { IDeviceInfo, ILinkedFI, IUserAccountInfo, UserState } from './user.interface';

const selectUser = createFeatureSelector<UserState>('user');

export const selectDevices = createSelector(
  selectUser,
  selectCore,
  (state: UserState, core: CoreState): LoaderState<IDeviceInfo[]> => {
    return {
      ...state.devices,
      data:
        state?.devices?.data?.length === 0
          ? []
          : state?.devices?.data?.map((device) => {
              return { ...device, current: device.device_ref === core.deviceId };
            }),
    };
  }
);

export const selectWebuathnUser = createSelector(selectUser, (state: UserState) => {
  return {
    userProfileRef: state?.userProfileRef || '',
    displayName: state?.userProfile?.data?.email || '',
  };
});
export const selectUserProfileRef = createSelector(selectUser, (state: UserState) => state?.userProfileRef ?? '');
export const selectFiUserId = createSelector(selectUser, (state: UserState) => state?.fiUserId ?? '');
export const selectUserProfile = createSelector(selectUser, (state: UserState) => state?.userProfile);
export const selectUserProfileError = createSelector(selectUser, (state: UserState) => state?.userProfile?.error);
export const selectUserProfileEmail = createSelector(
  selectUser,
  (state: UserState) => state.userProfile?.data?.email ?? ''
);
export const selectFilteredLinkedFiInfo = createSelector(
  selectUser,
  selectFilteredAccountDisableReasons,
  (state: UserState, filteredAccountDisableReasons) => {
    const linkedFiInfo: ILinkedFI[] = [];
    state?.userProfile?.data?.linked_fi_info.forEach((fi) => {
      const linkedFi = { ...fi };
      linkedFi.accounts =
        linkedFi?.accounts?.filter(
          (account: UserAccountInfo) =>
            !account?.disable_reason || !filteredAccountDisableReasons.includes(account.disable_reason)
        ) ?? [];
      linkedFiInfo.push(linkedFi);
    });
    return linkedFiInfo ?? [];
  }
);
export const selectUserProfileAccounts = createSelector(selectFilteredLinkedFiInfo, (linkedFiInfo) => {
  return (
    linkedFiInfo?.flatMap((fi) => fi?.accounts ?? []).filter((account): account is IUserAccountInfo => !!account) ?? []
  );
});

export const selectUserProfileLinkedFIs = createSelector(
  selectFilteredLinkedFiInfo,
  (linkedFiInfo) => linkedFiInfo ?? []
);
export const selectUserProfileAddresses = createSelector(
  selectUser,
  (state: UserState) => state.userProfile?.data?.shipping_addresses ?? []
);

export const selectDefaultFiId = createSelector(
  selectUser,
  (state: UserState) => state.userProfile?.data?.default_linked_fi_id ?? ''
);
export const selectDefaultAccountId = createSelector(
  selectUser,
  (state: UserState) => state.userProfile?.data?.default_fi_account_ref ?? ''
);
export const selectDefaultSavingAccountId = createSelector(
  selectUser,
  (state: UserState) => state.userProfile?.data?.default_fi_account_saving_ref ?? ''
);
export const selectShippingAddresses = createSelector(
  selectUser,
  (state: UserState) => state.userProfile?.data?.shipping_addresses ?? []
);
export const selectEmailVerificationStatus = createSelector(
  selectUser,
  (state: UserState) => state.emailVerification?.status ?? EMAIL_VERIFICATION_STATUS.PENDING
);
export const selectEmailVerification = createSelector(selectUser, (state: UserState) => state.emailVerification);
export const selectEmailVerificationTokenExpiry = createSelector(
  selectUser,
  (state: UserState) => state.emailVerification?.tokenExpiry
);
export const selectEmailVerificationError = createSelector(
  selectUser,
  (state: UserState) => state.emailVerification?.error
);
export const selectModifyShippingAddress = createSelector(
  selectUser,
  (state: UserState) => state.modifyShippingAddressList
);
export const selectUserProfileShippingAddressRef = createSelector(
  selectUser,
  (state: UserState) => state.shippingAddressRef
);
export const selectUserProfileDefaultShippingAddressRef = createSelector(
  selectUser,
  (state: UserState) => state.userProfile?.data?.default_shipping_address_ref ?? ''
);
export const selectVerifyEmail = createSelector(
  selectUser,
  (state: UserState) => state.emailVerification?.email ?? state.userProfile?.data?.email ?? ''
);
export const selectPostUserRegister = createSelector(selectUser, (state: UserState) => state?.userRegister);
export const selectRegisterStatus = createSelector(selectUser, (state: UserState) => state?.userRegistrationStatus);

export const selectFIsNotLinked = createSelector(
  selectUser,
  selectFinancialInstitutions,
  (userState: UserState, financialInstitutions) => {
    const linkedFIids = userState.userProfile?.data?.linked_fi_info.map((linked) => linked.id);
    const fis = financialInstitutions.data?.filter((i) => linkedFIids?.indexOf(i.id) === -1);
    return { data: fis, isLoading: financialInstitutions.isLoading, error: financialInstitutions.error };
  }
);
export const selectEmailRegistrationStatus = createSelector(
  selectUser,
  (state: UserState) => state?.emailRegistrationStatus
);
export const selectUserType = createSelector(selectUser, (state: UserState) => state?.userRegistrationStatus);

export const selectSelectedShippingAddress = createSelector(
  selectUserProfileDefaultShippingAddressRef,
  selectShippingAddresses,
  (shippingAddressRef: string, addresses: Array<ShippingAddress>) =>
    shippingAddressRef ? addresses.find((address) => address.shipping_address_ref === shippingAddressRef) : addresses[0]
);
